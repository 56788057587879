import React from 'react';

import './FooterOverlay.css';

const FooterOverlay = () => (
  <div className='autofris__footerOverlay'>
   <div className='autofris__footerOverlay-black'/>
   <div className='autofris__footerOverlay-img autofris__bg'/>
  </div>
);

export default FooterOverlay;
