import React from 'react';
import { images } from '../../constants'
import './Header.css';
import { SubHeading } from '../../components';



const Header = () => {
      return (

  <div className='autofris__header autofris__wrapper section__padding' id='home'>

   <div className='autofris__wrapper_info'>
    <SubHeading title="Destination assured"/>
    <h1 className='autofris__header-h1'>
      The Power of v8 Engines
    </h1>
    <p className='p__opensans' style={{margin: '2rem 0'}}>Vehicles with a V8 tend to have more horsepower than vehicles with a V6, although this varies 
    depending on the components of the engine. V8 engines 
    generally have more torque although, the difference can 
    be minimal in some models. The power of eight cylinders 
    allows for heavier hauls and higher towing capacity.</p>
    <button type='button' className='custom__button'>Explore Models</button>
      </div>


    <div className='autofris__wrapper_img'>
      <img src={images.v8engine} alt="v8engine" />
    </div>
      </div>
)
};

export default Header;
