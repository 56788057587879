import carbg from '../assets/carbg.png';
import engineers from '../assets/engineers.png';
import A from '../assets/A.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import cargear from '../assets/cargear.png';
import logo from '../assets/autofris.png';
import tyres from '../assets/tyres.png';
import v8engine from '../assets/v8engine.png';
import findus from '../assets/findus.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import autofris from '../assets/autofris.png';

export default {
  carbg,
  engineers,
  A,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  cargear,
  logo,
  tyres,
  v8engine,
  findus,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  autofris,
};
