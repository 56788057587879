import React from 'react';
import { SubHeading } from '../../components';
import { images, data } from '../../constants';


const FindUs = () => (
  <div className="autofris__bg autofris__wrapper section__padding" id="contact">
  <div className="autofris__wrapper_info">
    <SubHeading title="Contact" />
    <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>Find Us</h1>
    <div className="app__wrapper-content">
      <p className="p__opensans">Water Corporation Road, Victoria Island, Lagos, Nigeria</p>
      <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>Opening Hours</p>
      <p className="p__opensans">Mon - Fri: 09:00 am - 06:00 pm</p>
      <p className="p__opensans">Sat - Sun: 12:00 am - 04:00 pm</p>
    </div>
    <button type="button" className="custom__button" style={{ marginTop: '2rem' }}>Visit Us</button>
  </div>

  <div className="autofris__wrapper_img">
    <img src={images.findus} alt="finus_img" />
  </div>
</div>
);

export default FindUs;
