import React, {useState} from 'react';
import { MdOutlineMenuOpen } from 'react-icons/md';
import { SiNutanix } from 'react-icons/si'
import images from '../../constants/images';
import './Navbar.css';

const Navbar = () => {

  const [toggleMenu, setToggleMenu] = useState(false);

  return (
  <nav className='autofris__navbar'>
    <div className='autofris__navbar-logo'>
      <img src={images.autofris} alt="autofrissLogo" />
    </div>
    <ul className='autofris__navbar-links'>
      <li className='p__opensans'><a href="#home">Home</a></li>
      <li className='p__opensans'><a href="#about">About</a></li>
      <li className='p__opensans'><a href="#intro">Cars</a></li>
      <li className='p__opensans'><a href="#gallery">Gallery</a></li>
      <li className='p__opensans'><a href="#contact">Contact</a></li>
    </ul>
    <div className='autofris__navbar-login'>
      <button type='button'><a href="#login" className='p__opensans'>Log In</a></button>
      <div/>
      <button><a href="#bookasession" className='p__opensans'>Book a Session</a></button>
      </div>  

      <div className='autofris__navbar-smallscreen'>
        <MdOutlineMenuOpen color="#FFD700" fontSize={27} onClick={() => setToggleMenu(true)}/>
        

      {toggleMenu && (
        <div className='autofris__navbar-smallscreen_overlay flex__center slide-bottom'>
          <SiNutanix fontSize={27} className='overlay__close' onClick={() => setToggleMenu(false)}/>
      <ul className='autofris__navbar-smallscreen-links'>
      <li className='p__opensans'><a href="#home">Home</a></li>
      <li className='p__opensans'><a href="#about">About</a></li>
      <li className='p__opensans'><a href="#intro">Cars</a></li>
      <li className='p__opensans'><a href="#gallery">Gallery</a></li>
      <li className='p__opensans'><a href="#contact">Contact</a></li>
            </ul> 
            <div className='autofris__navbar_mobile-login'>
      <button type='button'><a href="#login" className='p__opensans'>Log In</a></button>
      <div/>
      <button><a href="#bookasession" className='p__opensans'>Book a Session</a></button>
      </div>  
        </div>
      )}


        </div>  
  </nav >
  )
};
export default Navbar;
