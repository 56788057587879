import React from 'react';
import {FiFacebook, FiTwitter, FiInstagram} from 'react-icons/fi'

import { images } from '../../constants';
import { FooterOverlay, Newsletter } from '../../components';
import './Footer.css';

const Footer = () => (
  <div className='autofris__footer section__padding'>
    <FooterOverlay />
    <Newsletter />

    <div className='autofris__footer-links'>
    <div className='autofris__footer-links_contact'>
      <h1 className='autofris__footer-headtext'>Contact Us</h1>
      <p className="p__opensans">Water Corporation Road, Victoria Island, Lagos, Nigeria</p>
        <p className="p__opensans">+234 910-344-1230</p>
        <p className="p__opensans">+234 810-555-1230</p>
    </div>

    <div className='autofris__footer-links_logo'>
    <img src={images.autofris} alt="footer_logo" />
        <p className="p__opensans">&quot;The best way to find yourself is to lose yourself in the service of others.&quot;</p>
        <img src={images.tyres} className="tyres__img" style={{ marginTop: 15 }} />

        <div className="autofris__footer-links_icons">
          <FiFacebook />
          <FiTwitter />
          <FiInstagram />
        </div>
    </div>

  

    <div className='autofris__footer-links_work'>
    <h1 className="autofris__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Monday-Friday:</p>
        <p className="p__opensans">09:00 am - 06:00 pm</p>
        <p className="p__opensans">Saturday-Sunday:</p>
        <p className="p__opensans">12:00 am - 04:00 pm</p>
    </div>


    </div>

    <div className='footer__copyright'>
      <p className='p__opensans'> 2023 Autofris. All Rights Reserved</p>
    </div>
  </div>
);

export default Footer;
 