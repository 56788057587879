import React from 'react';
import { images } from '../../constants';
import './AboutUs.css';


const AboutUs = () => (
  <div className='autofris__aboutus autofris__bg flex__center section__padding' id='about'>
  <div className='autofris__aboutus-overlay flex__center'>
    <img src={images.A} alt="aImage" />
  </div>

  <div className='autofris__aboutus-content flex__center'>



    <div className='autofris__aboutus-content_about'>
      <h1 className='headtext__cormorant'>About Us</h1>
      <img src={images.tyres} alt="about__tyres" className='tyres__img'/>
      <p className='p__opensans'>An Auto Mechanic that fixes vehicles and replaces 
      their parts for customers. Our duties include repairing 
      the vehicle's mechanical components, diagnosing problems
      with cars/ trucks and performing maintenance work on them 
      as well.</p>
      <button type='button' className='custom__button'>Know More</button>
    </div>

    <div className='autofris__aboutus-content_gear flex__center'>
      <img src={images.cargear} alt="aboutCarGear" />
    </div>

    <div className='autofris__aboutus-content_objective'>
      <h1 className='headtext__cormorant'>Our Objectives</h1>
      <img src={images.tyres} alt="about__tyres" className='tyres__img'/>
      <p className='p__opensans'>To create a substantial 
      customer base consisting of private and professional
       clients both in the domestic and international markets. To solve any problem arises in our field and provide quick solutions to all our customers.</p>
      <button type='button' className='custom__button'>Know More</button>
    </div>

  </div>
  </div>
);

export default AboutUs;
