import React from 'react'

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Engineers.css';

const Engineers = () => {
  return (

    <div className='autofris__bgd autofris__wrapper section__padding'>
      <div className='autofris__wrapper_img autofris__wrapper_img-reverse'>
    <img src={images.engineers} alt="engineersImage" />
      </div>


      <div className='autofris__wrapper_info'>
      <SubHeading title="Trained Engineers" />
      <h1 className='headtext__cormorant'>What we believe in</h1>

      <div className='autofris__engr-content'>
        <div className='autofris__engr-content_quote'>
        <img src={images.quote} alt="quote" />
        <p className='p__opensans'>Science can amuse and fascinate us all,
         but it is engineering that changes the world.</p>
        </div>
        <p className='p__opensans'>Automotive engineers are involved in
         the design, manufacture, distribution, marketing, sales 
         and after-sales care of cars (including racing cars), 
         motorbikes and other commercial vehicles. They work on 
         aesthetics as well as technical performance and, 
         increasingly, the electronics and software involved.</p>
      </div>

      <div className='autofris__engr-sign'>
      <p className='autofris__engr-name p__opensans'>Kevin Luo</p>
      <p className='p__opensans'>Engr & Founder</p>

      <img src={images.sign} alt="engrSign" />

      </div>

      </div>
      
      </div>
  )
}

export default Engineers;